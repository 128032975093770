/* eslint-disable no-invalid-this */
import React, { useState } from "react";
import PropTypes from "prop-types";
import SlickWrapper from "../../shared/slick-wrapper";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Component
const CarGreenPopup = loadable(() => import("../../shared/car-green-popup"));
const CarPrimePopup = loadable(() => import("../../shared/car-prime-popup"));
const CarLitePopup = loadable(() => import("../../shared/car-lite-popup"));
const PrivateSellerProListing = loadable(() => import("../private-seller-pro-listing/"));
const PrivateSellerListing = loadable(() => import("../private-seller-listing/"));

import LazyImage from "../../shared/lazy-image";
import IconHeart from "../../shared/icon-heart";
import withWishlist from "../with-wishlist/index";
import withLogin from "../with-login/index";
import Login from "../login-revamp";
import TrackableRecentlyAddedCarCardItem from "../trackable-recently-car-section-slider";

//Images
import IconPrimeTag from "../../shared/images/icons/prime-car-tag-new.svg";
import IconLiteTag from "../../shared/images/icons/lite-car-tag.svg";
import IconGreenTag from "../../shared/images/icons/green-car-tag.svg";
import IconPrivateSeller from "../../shared/images/icons/private-seller-tag.svg";
import IconPrivateProSeller from "../../shared/images/icons/private-seller-pro-tag.svg";

import BrandNewIcon from "../../shared/images/car-card-tags/brand-new.svg";
import ComingSoonIcon from "../../shared/images/car-card-tags/coming-soon.svg";
import InDemandIcon from "../../shared/images/car-card-tags/in-demand.svg";
import FeaturedIcon from "../../shared/images/car-card-tags/featured.svg";
import HotOfferIcon from "../../shared/images/car-card-tags/hot-offer.svg";

// Utils
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { getCarName, getEmiLabel, imageAltTagLabel } from "../../../utils/helpers/car-properties.ae";

// Constants
import { ASSORTMENT_CATEGORY_LABEL, FASTLY_BASE_URL, NUMBER } from "../../../constants/app-constants";
import { SOURCES } from "../login/constants";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { homeCarSections } from "../home-car-section/constants";
import { carCardTags } from "../../../constants/app-constants";

const RecentlyCarSectionSliderLanding = ({
    carList = [],
    handleCarClick = () => { },
    onSwipeCallBack = () => { },
    isWishlistedCar = () => { },
    onClickAddToWishList = () => { },
    showWishlistNudgeConnect,
    preloadNumber = NUMBER.ZERO,
    secureToken,
    isLoggedIn,
    settings = {},
    carsToShow = NUMBER.TEN,
    sectionName = ""
}) => {
    const [showLogin, setShowLogin] = useState(false);

    const carCardMapping = {
        [carCardTags["IN DEMAND"]]: {
            style: "styles.inDemand",
            imageIcon: InDemandIcon
        },
        [carCardTags.FEATURED]: {
            style: "styles.featured",
            imageIcon: FeaturedIcon
        },
        [carCardTags["HOT OFFER"]]: {
            style: "styles.hotOffer",
            imageIcon: HotOfferIcon
        },
        [carCardTags.RESERVED]: {
            style: "styles.reserved",
            imageIcon: null
        },
        [carCardTags["BRAND NEW"]]: {
            style: "styles.brandNew",
            imageIcon: BrandNewIcon
        },
        [carCardTags["COMING SOON"]]: {
            style: "styles.comingSoon",
            imageIcon: ComingSoonIcon
        },
        [carCardTags.SOLD]: {
            style: "styles.soldTag",
            imageIcon: null
        }
    };

    const SLIDER_SETTINGS = {
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: false,
        arrow: true,
        dots: false,
        gap: 16,
        ...settings
    };

    const carCategory = {
        [ASSORTMENT_CATEGORY_LABEL.PRIME]: { icon: IconPrimeTag, component: CarPrimePopup, iconWidth: "69" },
        [ASSORTMENT_CATEGORY_LABEL.LITE]: { icon: IconLiteTag, component: CarLitePopup, iconWidth: "57" },
        [ASSORTMENT_CATEGORY_LABEL.GREEN]: { icon: IconGreenTag, component: CarGreenPopup, iconWidth: "71" },
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: { icon: IconPrivateSeller,  iconWidth: "93",  component: PrivateSellerListing },
        [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: { icon: IconPrivateProSeller, iconWidth: "118", component: PrivateSellerProListing }
    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // eslint-disable-next-line max-params
    const handleOnClick = (e, relativeURL, carName, index, carId) => {
        preventPropagation(e);
        handleCarClick({relativeURL, carName, index, carId});
    };

    const onLoginSuccess = (appointmentId, data) => {
        const token = secureToken || data?.token;
        if (isWishlistedCar(appointmentId)) {
            trackDesktopCustomEventsAE("removeFromWishlist", {
                eventLabel: "Remove from wishlist - home page"
            });
            onClickAddToWishList(appointmentId, token, { removeFromWishlist: true }, false);
            setShowLogin(null);
        } else {
            onClickAddToWishList(appointmentId, token, {
                callback: (shortListed) => {
                    if (shortListed) {
                        trackDesktopCustomEventsAE("notifyMeSuccess");
                        showWishlistNudgeConnect();
                    }
                    trackDesktopCustomEventsAE("addToWishlistDetailPage", {
                        eventLabel: "add_to_wishlist - home page"
                    });
                    setShowLogin(null);
                }
            }, true);
        }
    };

    const handleWishlistClick = (appointmentId, event) => {
        if (event && event.stopPropagation && event.preventDefault) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (sectionName === homeCarSections.RECENTLY_VIEWED_CARS) {
            trackDesktopCustomEventsAE("recentlyViewedCarWishlistClick", {eventLabel: appointmentId});
        } else if (sectionName === homeCarSections.RECOMMENDED_CARS) {
            trackDesktopCustomEventsAE("recommendedCarWishlistClick", {eventLabel: appointmentId});
        }
        if (isLoggedIn) {
            onLoginSuccess(appointmentId);
        } else {
            setShowLogin(appointmentId);
        }
    };

    return (
        <div styleName={"styles.recentlySectionSlider"}>
            <div styleName={"styles.carsRow"}>
                <Login isVisible={showLogin} onSuccess={onLoginSuccess.bind(this, showLogin)} onClose={setShowLogin.bind(null, null)} loginFor={SOURCES.ADD_TO_WISHLIST}>
                    <SlickWrapper {...SLIDER_SETTINGS}>
                        {
                            (carList || [])?.slice(0, carsToShow)?.map((car, index) => {
                                const { appointmentId: carId, year, make, model, city, assortmentCategory, mainImage, carCardTag } = car || {};
                                const carName = `${make} ${model}`;
                                const { relativeURL, absoluteURL } = getDetailPageUrl({ year, carName, carId, city, country: "ae" });
                                const carImgSrc = fastlyUrl({ basePath: `${FASTLY_BASE_URL}/`, path: car?.mainImage?.path, type: imageTypes.aeCarDetailPreview, width: 200, queryParams: mainImage?.params });

                                return (
                                    <React.Fragment key={index}>
                                        {index < preloadNumber && preloadNumber && <Helmet>
                                            <link rel="preload" href={carImgSrc} as={"image"} type="image/webp" />
                                        </Helmet>}
                                        <TrackableRecentlyAddedCarCardItem
                                            onVisibleCallback={onSwipeCallBack}
                                            carId={carId}
                                        >
                                            <a
                                                href={absoluteURL}
                                                onClick={(e) => handleOnClick(e, relativeURL, carName, index, carId)}
                                                key={index}
                                            >
                                                <div styleName={"styles.carCardWrap"} key={index}>
                                                    {carCardTag && <span styleName={`styles.carCardTag ${carCardMapping[carCardTag]?.style}`}>{ carCardMapping[carCardTag]?.imageIcon && <img src={carCardMapping[carCardTag]?.imageIcon} width={"10"} height={"10"}/> }<span>{carCardTag}</span></span>}
                                                    <span styleName={"styles.carImage"}>
                                                        {
                                                            index < preloadNumber && preloadNumber ?
                                                                <img styleName={"styles.carImageMain"} src={carImgSrc} alt={imageAltTagLabel(car)} /> :
                                                                <LazyImage styleName={"styles.carImageMain"} src={carImgSrc} alt={imageAltTagLabel(car)}
                                                                />
                                                        }
                                                        {carCategory?.[assortmentCategory] && (
                                                            <React.Fragment>
                                                                <div styleName={"styles.primeTag"}>
                                                                    <img width={carCategory?.[assortmentCategory]?.iconWidth || "56"} height="16" alt="Tag" src={carCategory?.[assortmentCategory]?.icon} />
                                                                </div>
                                                            </React.Fragment>
                                                        )}

                                                        <div styleName={"styles.heartWrap"}>
                                                            <IconHeart
                                                                active={isWishlistedCar(carId) && isLoggedIn}
                                                                onClick={handleWishlistClick.bind(this, carId)}
                                                                source="desktopLanding"
                                                            />
                                                        </div>
                                                    </span>

                                                    <div styleName={"styles.carDetail"}>
                                                        <div>
                                                            <p styleName={"styles.carName"}>{getCarName(car)}</p>
                                                            <p styleName={"styles.carPrice"}>EMI from {getEmiLabel(car)}/mo</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </TrackableRecentlyAddedCarCardItem>
                                    </React.Fragment>
                                );
                            })
                        }
                    </SlickWrapper>
                </Login>
            </div>
        </div>
    );
};

RecentlyCarSectionSliderLanding.propTypes = {
    carList: PropTypes.array,
    handleCarClick: PropTypes.func,
    onSwipeCallBack: PropTypes.func,
    isWishlistedCar: PropTypes.func,
    onClickAddToWishList: PropTypes.func,
    showWishlistNudgeConnect: PropTypes.func,
    preloadNumber: PropTypes.number,
    carsToShow: PropTypes.number,
    settings: PropTypes.object,
    secureToken: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    sectionName: PropTypes.string
};

export default withWishlist(withLogin(RecentlyCarSectionSliderLanding));
